import { Add } from '@mui/icons-material'
import {
  Autocomplete,
  IconButton,
  Paper
} from '@mui/material'
import { ClearIcon } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format, parse } from 'date-fns'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addMeeting, selectError, selectLoading } from '../../../business/reducers/meetingReducer'
import {
  fetchSources,
  selectSourceLoading,
  selectSources,
} from '../../../business/reducers/sourceReducer'
import {
  fetchSpaces,
  selectSpaceLoading,
  selectSpaces,
} from '../../../business/reducers/spaceReducer'
import { fetchUsers, selectUserLoading, selectUsers } from '../../../business/reducers/userReducer'
import { AppDispatch } from '../../../business/store/store'
import { MeetingRequest, Source, Space, User } from '../../../business/utils/types'
import PrimaryAccordion from '../../components/Accordion'
import DialogConfirm from '../../components/DialogConfirm'
import ErrorAlert from '../../components/Error'
import ErrorMessage from '../../components/FormError'
import LoadingScreen from '../../components/Loading'
import Navbar from '../../components/NavBar'
import SubmitButton from '../../components/SubmitButton'
import PrimaryTextField from '../../components/TextField'
import React from 'react'

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('UTC')

const CreateMeeting = () => {
  const dispatch = useDispatch<AppDispatch>()
  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const users = useSelector(selectUsers)
  const [userAutocompleteOptions, setUserAutocompleteOptions] = useState<User[]>([])
  const userLoading = useSelector(selectUserLoading)
  const spaces = useSelector(selectSpaces)
  const [spaceAutocompleteOptions, setSpaceAutocompleteOptions] = useState<Space[]>([])
  const spaceLoading = useSelector(selectSpaceLoading)
  const sources = useSelector(selectSources)
  const [sourceAutocompleteOptions, setSourceAutocompleteOptions] = useState<Source[]>([])
  const sourceLoading = useSelector(selectSourceLoading)
  const [externalUsersFields, setExternalUsersFields] = useState([{ id: Date.now() }])
  const [internalUsersFields, setInternalUsersFields] = useState([{ id: Date.now() }])
  const [dialogOpen, setDialogOpen] = useState(false)

  const loading = useSelector(selectLoading)
  const error = useSelector(selectError)

  const { t } = useTranslation()

  const registerOptions = {
    date: {
      required: 'Date is required',
    },
    source: {
      required: 'Source is required',
    },
    space: {
      required: 'Space is required',
    },
    title: {
      required: 'Title is required',
    },
  }

  useEffect(() => {
    dispatch(fetchUsers())
    dispatch(fetchSpaces())
    dispatch(fetchSources())
  }, [dispatch])

  useEffect(() => {
    setUserAutocompleteOptions(users)
  }, [users])

  useEffect(() => {
    setSpaceAutocompleteOptions(spaces)
  }, [spaces])

  useEffect(() => {
    setSourceAutocompleteOptions(sources)
  }, [sources])

  const addExternalUserField = () => {
    setExternalUsersFields([...externalUsersFields, { id: Date.now() }])
  }

  const removeExternalUserField = (id: number) => {
    setExternalUsersFields(externalUsersFields.filter((field) => field.id !== id))
  }

  const addInternalUserField = () => {
    setInternalUsersFields([...internalUsersFields, { id: Date.now() }])
  }

  const removeInternalUserField = (id: number) => {
    setInternalUsersFields(internalUsersFields.filter((field) => field.id !== id))
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleConfirmDialog = () => {
    setDialogOpen(false)
    const meeting: MeetingRequest = getValues() as MeetingRequest
    const dateFormat = "EEE, dd MMM yyyy HH:mm:ss 'GMT'"
    const formattedDate = format(
      parse(meeting.date.toString(), dateFormat, new Date()),
      'yyyy-MM-dd'
    )
    meeting.date = formattedDate
    if (meeting) {
      dispatch(addMeeting(meeting))
    }
  }

  const onSubmit = (data: unknown) => {
    console.log(data)
    setDialogOpen(true)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        padding: '20px',
      }}
    >
      <Navbar />
      {loading ? (
        <LoadingScreen />
      ) : (
        <Paper sx={{ padding: '20px', width: '80%', marginTop: '80px' }} elevation={3}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="date"
                control={control}
                defaultValue={null}
                rules={registerOptions.date}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={t('meetingForm.dateLabel')}
                    onChange={(date) => field.onChange(date)}
                    sx={{ marginTop: '16px', width: '100%' }}
                  />
                )}
              />
              <ErrorMessage error={errors?.date} />
            </LocalizationProvider>
            <Controller
              name="space"
              control={control}
              defaultValue={null}
              rules={registerOptions.space}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, data) => field.onChange(data)}
                  options={spaceAutocompleteOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.id}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <PrimaryTextField
                      params={params}
                      label={t('meetingForm.spaceAutocompleteHint')}
                    />
                  )}
                  loading={spaceLoading}
                  loadingText={t('meetingForm.spaceAutocompleteLoading')}
                />
              )}
            />
            <ErrorMessage error={errors?.space} />
            <Controller
              name="source"
              control={control}
              defaultValue={null}
              rules={registerOptions.source}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, data) => {
                    field.onChange(data)
                    if (data && getValues('title').toString().trim() === '') {
                      setValue('title', data.title)
                    }
                  }}
                  options={sourceAutocompleteOptions}
                  getOptionLabel={(option) => option.title}
                  getOptionKey={(option) => option.id}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <PrimaryTextField
                      params={params}
                      label={t('meetingForm.sourceAutocompleteHint')}
                    />
                  )}
                  loading={sourceLoading}
                  loadingText={t('meetingForm.sourceAutocompleteLoading')}
                />
              )}
            />
            <ErrorMessage error={errors?.source} />
            <Controller
              name="title"
              control={control}
              defaultValue=""
              rules={registerOptions.title}
              render={({ field }) => (
                <PrimaryTextField params={field} label={t('meetingForm.titleLabel')} />
              )}
            />
            <ErrorMessage error={errors?.title} />
            <br />
            <PrimaryAccordion
              title={t('meetingForm.externalUsersAccordionTitle')}
              id="externalUsers"
            >
              {externalUsersFields.map((field, index) => (
                <div
                  key={'externalUserField' + index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1rem',
                    width: '70%',
                  }}
                >
                  <Controller
                    name={`externalUsers[${index}]`}
                    control={control}
                    render={({ field }) => (
                      <PrimaryTextField
                        params={field}
                        label={t('meetingForm.userTextFieldLabel') + ' ' + (index + 1)}
                      />
                    )}
                  />
                  <IconButton onClick={() => removeExternalUserField(field.id)} aria-label="delete">
                    <ClearIcon />
                  </IconButton>
                </div>
              ))}
              <IconButton onClick={addExternalUserField}>
                <Add />
              </IconButton>
            </PrimaryAccordion>
            <PrimaryAccordion
              title={t('meetingForm.internalUsersAccordionTitle')}
              id="internalUsers"
            >
              {internalUsersFields.map((field, index) => (
                <div
                  key={'internalUserField' + index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1rem',
                    width: '100%',
                  }}
                >
                  <Controller
                    name={`internalUsers[${index}]`}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        onChange={(_, data) => field.onChange(data)}
                        options={userAutocompleteOptions}
                        getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                        getOptionKey={(option) => option.uuid}
                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                          <PrimaryTextField
                            params={params}
                            label={t('meetingForm.userAutocompleteHint') + ' ' + (index + 1)}
                          />
                        )}
                        loading={userLoading}
                        loadingText={t('meetingForm.userAutocompleteLoading')}
                      />
                    )}
                  />
                  <IconButton onClick={() => removeInternalUserField(field.id)} aria-label="delete">
                    <ClearIcon />
                  </IconButton>
                </div>
              ))}
              <IconButton onClick={addInternalUserField}>
                <Add />
              </IconButton>
            </PrimaryAccordion>
            <SubmitButton text={t('meetingForm.submitText')} />
          </form>
        </Paper>
      )}
      {error && error !== null && <ErrorAlert errorMessage={error} />}
      <DialogConfirm
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        title={t('dialog.addMeeting.title')}
        content={t('dialog.addMeeting.content')}
      />
    </div>
  )
}

export default CreateMeeting
