import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Meeting, MeetingRequest, MeetingState, SyncRequest } from '../utils/types'
import customAxios from '../http/axiosInstance'
import { MeetingDto, UserDto } from '../utils/dtos'
import { dtoToMeeting, dtoToUser, meetingRequestToDto, syncRequestToDto } from '../utils/mappings'

const initialState: MeetingState = {
  meetings: [],
  loading: false,
  error: null,
}

export const fetchMeetings = createAsyncThunk('meeting/fetchMeetings', async () => {
  try {
    const response = await customAxios.get<MeetingDto[]>('/meetings')
    return response.data.map((meetingDto) => dtoToMeeting(meetingDto))
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch meetings')
  }
})

export const retryMeeting = createAsyncThunk('meeting/retryMeeting', async (uuid: string) => {
  try {
    const response = await customAxios.patch<MeetingDto>('/meetings/' + uuid + '/retry')
    return dtoToMeeting(response.data)
  } catch (error) {
    console.error(error)
    throw new Error('Failed to retry meeting')
  }
})

export const addMeeting = createAsyncThunk(
  'meeting/addMeeting',
  async (meeting: MeetingRequest) => {
    const meetingRequestDto = meetingRequestToDto(meeting)
    try {
      const response = await customAxios.post<MeetingDto>('/meetings', meetingRequestDto)
      return dtoToMeeting(response.data)
    } catch (error) {
      console.error(error)
      throw new Error('Failed to add meeting')
    }
  }
)

export const syncFolder = createAsyncThunk(
  'meetings/syncActivate',
  async (syncRequest: SyncRequest) => {
    const syncRequestDto = syncRequestToDto(syncRequest)

    try {
      const response = await customAxios.post<UserDto>('/meetings/trigger/activate', syncRequestDto)
      return dtoToUser(response.data)
    } catch (error) {
      console.error(error)
      throw new Error('Failed to activate folder trigger')
    }
  }
)

export const removeSync = createAsyncThunk('meetings/syncDeactivate', async () => {
  try {
    const response = await customAxios.delete<UserDto>('/meetings/trigger/deactivate')
    return dtoToUser(response.data)
  } catch (error) {
    console.error(error)
    throw new Error('Failed to deactivate folder trigger')
  }
})

const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeetings.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchMeetings.fulfilled, (state, action: PayloadAction<Meeting[]>) => {
        state.loading = false
        state.meetings = action.payload
      })
      .addCase(fetchMeetings.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to fetch meetings'
      })
      .addCase(retryMeeting.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(retryMeeting.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(retryMeeting.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to retry meetings'
      })
      .addCase(addMeeting.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(addMeeting.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(addMeeting.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to add meeting'
      })
      .addCase(syncFolder.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(syncFolder.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(syncFolder.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to sync folder'
      })
      .addCase(removeSync.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(removeSync.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(removeSync.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to deactive folder sync'
      })
  },
})

export const selectMeetings = (state: { meeting: { meetings: Meeting[] } }) =>
  state.meeting.meetings
export const selectLoading = (state: { meeting: { loading: boolean } }) => state.meeting.loading
export const selectError = (state: { meeting: { error: string | null } }) => state.meeting.error

export default meetingSlice.reducer
