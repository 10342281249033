import React from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import {} from 'react-router-dom'
import ProfileComponent from './ui/pages/profile/Profile'
import {
  ROUTE_CREATE_MEETING,
  ROUTE_LOGIN,
  ROUTE_MEETING_LIST,
  ROUTE_OAUTH_CALLBACK,
  ROUTE_PROFILE,
} from './business/utils/routes'
import ProtectedRoute from './ProtectedRoute'
import OAuthCallback from './ui/pages/auth/OAuthCallback'
import CreateMeeting from './ui/pages/createmeeting/CreateMeeting'
import Landing from './ui/pages/landing/Landing'
import Login from './ui/pages/login/Login'
import MeetingList from './ui/pages/meetinglist/MeetingList'

function App() {
  return (
    <Routes>
      <Route path={ROUTE_LOGIN} element={<Login />} />
      <Route path={ROUTE_OAUTH_CALLBACK} element={<OAuthCallback />} />
      <Route
        path={ROUTE_MEETING_LIST}
        element={
          <ProtectedRoute>
            <MeetingList />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTE_CREATE_MEETING}
        element={
          <ProtectedRoute>
            <CreateMeeting />
          </ProtectedRoute>
        }
      />
      <Route
        path={ROUTE_PROFILE}
        element={
          <ProtectedRoute>
            <ProfileComponent />
          </ProtectedRoute>
        }
      />
      <Route path="/" element={<Landing />} />
    </Routes>
  )
}

export default App
