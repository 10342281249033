import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Space, SpaceState } from '../utils/types'
import customAxios from '../http/axiosInstance'
import { SpaceDto } from '../utils/dtos'
import { dtoToSpace } from '../utils/mappings'

const initialState: SpaceState = {
  spaces: [],
  loading: false,
  error: null,
}

export const fetchSpaces = createAsyncThunk('space/fetchSpaces', async () => {
  try {
    const response = await customAxios.get<SpaceDto[]>('/reports/spaces')
    return response.data.map((spaceDto) => dtoToSpace(spaceDto))
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch spaces')
  }
})

const spaceSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpaces.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchSpaces.fulfilled, (state, action: PayloadAction<Space[]>) => {
        state.loading = false
        state.spaces = action.payload
      })
      .addCase(fetchSpaces.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to fetch spaces'
      })
  },
})

export const selectSpaces = (state: { space: { spaces: Space[] } }) => state.space.spaces
export const selectSpaceLoading = (state: { space: { loading: boolean } }) => state.space.loading
export const selectSpaceError = (state: { space: { error: string | null } }) => state.space.error

export default spaceSlice.reducer
