import React from 'react'
import Button from '@mui/material/Button'

interface SubmitButtonProps {
  text: string
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ text }) => {
  return (
    <Button type="submit" variant="contained" color="primary" style={{ marginTop: 16 }}>
      {text}
    </Button>
  )
}

export default SubmitButton
