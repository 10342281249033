import React, { ReactElement, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from './business/store/store';

  const ProtectedRoute: React.FC<{ children: ReactNode }>  = ({ children }): ReactElement | null => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    if (!isAuthenticated) {
      // user is not authenticated
      return <Navigate to="/login" />;
    }
    return children as ReactElement;
  };

export default ProtectedRoute;