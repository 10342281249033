import { Typography } from '@mui/material'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import React from 'react'

const ErrorMessage = ({
  error,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined
}) => {
  return (
    <>
      {error && (
        <Typography color={'error'} variant={'body2'}>
          {error.message?.toString()}
        </Typography>
      )}
    </>
  )
}

export default ErrorMessage
