import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { jwtDecode } from 'jwt-decode'
import { JwtPayload } from '../utils/types'
import customAxios, { updateInterceptor } from '../http/axiosInstance'
import { RefreshTokenDto, RefreshTokenRequestDto } from '../utils/dtos'
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from '../utils/constants'

const getLoggedUser = () => {
  if (localStorage.getItem('accessToken') !== null) {
    const { firstName, lastName } = jwtDecode(
      localStorage.getItem('accessToken') as string
    ) as JwtPayload
    return firstName + ' ' + lastName
  }
  return null
}

export const getLoggedUserUuid = () => {
  if (localStorage.getItem('accessToken') !== null) {
    const { uuid } = jwtDecode(localStorage.getItem('accessToken') as string) as JwtPayload
    return uuid
  }
  return null
}

export const requestTokenRefresh = createAsyncThunk('auth/refresh', async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken === null) {
      throw new Error('Refresh token not found')
    }
    const refreshTokenDto: RefreshTokenRequestDto = { refresh_token: refreshToken as string }
    const response = await customAxios.post<RefreshTokenDto>('/auth/refresh', refreshTokenDto)
    return response.data.access_token
  } catch (error) {
    console.error(error)
    throw new Error('Failed to refresh token')
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: localStorage.getItem('accessToken') !== null,
    accessToken: localStorage.getItem('accessToken'),
    loggedUserName: getLoggedUser(),
    refreshStatus: 'idle',
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true
      state.accessToken = action.payload.accessToken
      localStorage.setItem('accessToken', action.payload.accessToken)
      localStorage.setItem('refreshToken', action.payload.refreshToken)
      state.loggedUserName = getLoggedUser()
      updateInterceptor()
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.accessToken = null
      state.loggedUserName = null
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestTokenRefresh.pending, (state) => {
        state.refreshStatus = STATUS_LOADING
      })
      .addCase(requestTokenRefresh.fulfilled, (state, action) => {
        state.refreshStatus = STATUS_SUCCESS
        state.isAuthenticated = true
        state.accessToken = action.payload
        localStorage.setItem('accessToken', action.payload)
        state.loggedUserName = getLoggedUser()
        updateInterceptor()
      })
      .addCase(requestTokenRefresh.rejected, (state) => {
        state.refreshStatus = STATUS_ERROR
        state.isAuthenticated = false
        state.accessToken = null
        localStorage.removeItem('accessToken')
      })
  },
})

export const { login, logout } = authSlice.actions
export default authSlice.reducer
