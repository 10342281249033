import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import React, { ReactNode } from 'react'

interface AccordionProps {
  children: ReactNode
  title: string
  id: string
}

const PrimaryAccordion: React.FC<AccordionProps> = ({ children, title, id }) => {
  return (
    <Accordion style={{ marginTop: 16 }}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls={id + '-content'}
        id={id + '-header'}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export default PrimaryAccordion
