import React from 'react'
import TextField from '@mui/material/TextField'

interface TextFieldProps {
  params?: object
  label: string
  value?: string
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const PrimaryTextField: React.FC<TextFieldProps> = ({
  params,
  label,
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <TextField
      {...(params ? params : {})}
      label={label}
      variant="outlined"
      style={{ marginTop: 16, width: '100%' }}
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

export default PrimaryTextField
