import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../../components/NavBar'
import { Paper, Typography } from '@mui/material'
import ErrorAlert from '../../components/Error'
import LoadingScreen from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import { AppDispatch } from '../../../business/store/store'
import {
  fetchLoggedUserProfile,
  selectProfile,
  selectProfileError,
  selectProfileLoading,
} from '../../../business/reducers/profileReducer'
import type { Profile, SyncRequest } from '../../../business/utils/types'
import PrimaryTextField from '../../components/TextField'
import PrimaryButton from '../../components/Button'
import DialogConfirm from '../../components/DialogConfirm'
import { removeSync, selectError, syncFolder } from '../../../business/reducers/meetingReducer'

const ProfileComponent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const profileData = useSelector(selectProfile)
  const loading = useSelector(selectProfileLoading)
  const error = useSelector(selectProfileError) || useSelector(selectError)
  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogBody, setDialogBody] = useState('')
  const [dialogConfirmAction, setDialogConfirmAction] = useState<() => void>(() => {
    0
  })
  const [profile, setProfile] = useState<Profile | null>(null)
  const [sourceDirectoryId, setSourceDirectoryId] = useState<string>('')

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleActivateConfirmDialog = async () => {
    setDialogOpen(false)
    if (profile === null) return
    const request: SyncRequest = {
      sourceId: sourceDirectoryId,
    }

    await dispatch(syncFolder(request))
    dispatch(fetchLoggedUserProfile())
  }

  const handleDeactivateConfirmDialog = async () => {
    setDialogOpen(false)
    await dispatch(removeSync())
    dispatch(fetchLoggedUserProfile())
  }

  const handleActiveSyncDialog = () => {
    setDialogTitle(t('profile.activateSyncDialog.title'))
    setDialogBody(t('profile.activateSyncDialog.content'))
    setDialogConfirmAction(() => handleActivateConfirmDialog)
    setDialogOpen(true)
  }

  const handleDectiveSyncDialog = () => {
    setDialogTitle(t('profile.deactivateSyncDialog.title'))
    setDialogBody(t('profile.deactivateSyncDialog.content'))
    setDialogConfirmAction(() => handleDeactivateConfirmDialog)
    setDialogOpen(true)
  }

  useEffect(() => {
    dispatch(fetchLoggedUserProfile())
  }, [dispatch])

  useEffect(() => {
    if (profileData) {
      setProfile(profileData)
      setSourceDirectoryId(profileData.sourceDirectoryId)
    }
  }, [profileData])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        padding: '20px',
      }}
    >
      <Navbar />
      {loading ? (
        <LoadingScreen />
      ) : (
        profile && (
          <Paper sx={{ padding: '40px', width: '80%', marginTop: '80px' }} elevation={3}>
            <Typography variant={'h4'}>{profile.firstName + ' ' + profile.lastName}</Typography>
            <Typography variant={'body1'}>{profile.email}</Typography>
            <br />
            <br />
            <Typography variant={'body1'}>
              {t('profile.genActiveLabel')}:{' '}
              {profile.isGenActive ? t('profile.genActiveYes') : t('profile.genActiveNo')}
            </Typography>
            <br />
            <PrimaryTextField
              label={t('profile.sourceDirectoryLabel')}
              value={sourceDirectoryId ?? ''}
              disabled={profile.isGenActive}
              onChange={(e) => setSourceDirectoryId(e.target.value)}
            />
            {!profile.isGenActive && (
              <PrimaryButton onClick={handleActiveSyncDialog}>
                {t('profile.activateGenButton')}
              </PrimaryButton>
            )}
            {profile.isGenActive && (
              <PrimaryButton onClick={handleDectiveSyncDialog}>
                {t('profile.deactivateGenButton')}
              </PrimaryButton>
            )}
          </Paper>
        )
      )}
      {error && error !== null && <ErrorAlert errorMessage={error} />}
      <DialogConfirm
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={dialogConfirmAction}
        title={dialogTitle}
        content={dialogBody}
      />
    </div>
  )
}

export default ProfileComponent
