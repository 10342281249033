import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingScreen = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '20px',
      }}
    >
      <CircularProgress />
    </div>
  )
}

export default LoadingScreen
