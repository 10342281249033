import React, { ReactNode } from 'react'
import Button from '@mui/material/Button'

interface ButtonProps {
  children: ReactNode
  onClick?: () => void
}

const PrimaryButton: React.FC<ButtonProps> = ({ children, onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      style={{ marginTop: 16, marginBottom: 16 }}
    >
      {children}
    </Button>
  )
}

export default PrimaryButton
