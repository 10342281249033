import {
  MeetingDto,
  MeetingRequestDto,
  SourceDto,
  SpaceDto,
  SyncRequestDto,
  UserDto,
  UserInternalDto,
} from './dtos'
import { Meeting, MeetingRequest, Profile, Source, Space, SyncRequest, User } from './types'

export function dtoToMeeting(meeting: MeetingDto): Meeting {
  return {
    uuid: meeting.uuid,
    title: meeting.title,
    date: meeting.date,
    step: meeting.step,
    status: meeting.status,
    creationDate: meeting.created_at,
    lastUpdate: meeting.updated_at,
  }
}

export function dtoToUser(user: UserDto): User {
  return {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
  }
}

export function dtoToProfile(user: UserInternalDto): Profile {
  return {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    isGenActive: user.is_gen_active,
    sourceDirectoryId: user.source_directory_id,
    syncExp: user.sync_exp,
  }
}

export function dtoToSpace(space: SpaceDto): Space {
  return {
    id: space.id,
    name: space.name,
    key: space.key,
    url: space.url,
  }
}

export function dtoToSource(source: SourceDto): Source {
  return {
    id: source.id,
    title: source.title,
    type: source.type,
    isTrashed: source.is_trashed,
    url: source.url,
  }
}

export function meetingRequestToDto(meeting: MeetingRequest): MeetingRequestDto {
  return {
    title: meeting.title,
    date: meeting.date,
    partecipants: meeting.internalUsers
      .filter((user) => user !== null)
      .map((user) => ({
        email: user.email || null,
        full_name: `${user.firstName} ${user.lastName}`,
      }))
      .concat(
        meeting.externalUsers
          .filter((user) => user !== null && user !== undefined && user.trim() !== '')
          .map((user) => ({
            email: null,
            full_name: user,
          }))
      ),
    source_file_id: meeting.source.id,
    documental_space_id: meeting.space.id,
    documental_parent_id: '',
  }
}

export function syncRequestToDto(syncRequest: SyncRequest): SyncRequestDto {
  return {
    source_id: syncRequest.sourceId,
  }
}
