import React, { useEffect, useState } from 'react'
import Navbar from '../../components/NavBar'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchMeetings,
  retryMeeting,
  selectError,
  selectLoading,
  selectMeetings,
} from '../../../business/reducers/meetingReducer'
import { AppDispatch } from '../../../business/store/store'
import { format } from 'date-fns'
import LoadingScreen from '../../components/Loading'
import ErrorAlert from '../../components/Error'
import { Meeting, TableInfo } from '../../../business/utils/types'
import DataTable from '../../components/DataTable'
import { Replay } from '@mui/icons-material'
import DialogConfirm from '../../components/DialogConfirm'
import { useTranslation } from 'react-i18next'
import { Paper } from '@mui/material'

const MeetingList = () => {
  const dispatch = useDispatch<AppDispatch>()
  const meetings = useSelector(selectMeetings)
  const loading = useSelector(selectLoading)
  const error = useSelector(selectError)
  const { t } = useTranslation()

  const [tableInfo, setTableInfo] = useState<TableInfo>({
    headers: [
      'meetings.table.header1',
      'meetings.table.header2',
      'meetings.table.header3',
      'meetings.table.header4',
      'meetings.table.header5',
      'meetings.table.header6',
      'meetings.table.header7',
    ],
    data: [],
  })
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | null>(null)

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleConfirmDialog = () => {
    setDialogOpen(false)
    if (selectedMeeting) {
      dispatch(retryMeeting(selectedMeeting.uuid))
    }
  }

  useEffect(() => {
    dispatch(fetchMeetings())
  }, [dispatch])

  useEffect(() => {
    const updatedTableInfo: TableInfo = {
      headers: tableInfo.headers,
      data: meetings.map((meeting) => ({
        uuid: meeting.uuid,
        columns: [
          meeting.title,
          format(new Date(meeting.date), 'dd/MM/yyyy'),
          meeting.step,
          meeting.status,
          format(new Date(meeting.creationDate), 'dd/MM/yyyy'),
          format(new Date(meeting.lastUpdate), 'dd/MM/yyyy'),
        ],
        actions: [
          {
            icon: <Replay />,
            action: (uuid: string) => {
              const meeting = meetings.find((meeting) => meeting.uuid === uuid)
              if (!meeting) return
              setDialogTitle(t('dialog.retry.title') + ' ' + meeting?.title)
              setSelectedMeeting(meeting)
              handleOpenDialog()
            },
          },
        ],
      })),
    }
    setTableInfo(updatedTableInfo)
  }, [meetings, dispatch])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        padding: '20px',
      }}
    >
      <Navbar />
      {loading ? (
        <LoadingScreen />
      ) : (
        <Paper sx={{ padding: '40px', width: '80%', marginTop: '80px' }} elevation={3}>
          <DataTable tableInfo={tableInfo} />
        </Paper>
      )}
      {error && error !== null && <ErrorAlert errorMessage={error} />}
      <DialogConfirm
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        title={dialogTitle}
        content={t('dialog.retry.content')}
      />
    </div>
  )
}

export default MeetingList
