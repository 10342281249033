import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from '../reducers/authReducer'
import meetingReducer from '../reducers/meetingReducer'
import { setupInterceptors } from '../http/axiosInstance'
import userReducer from '../reducers/userReducer'
import spaceReducer from '../reducers/spaceReducer'
import sourceReducer from '../reducers/sourceReducer'
import profileReducer from '../reducers/profileReducer'

const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    meeting: meetingReducer,
    user: userReducer,
    space: spaceReducer,
    source: sourceReducer,
    profile: profileReducer,
  }),
})

setupInterceptors(store)

export type AppStore = typeof store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
