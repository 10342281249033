import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Profile, ProfileState } from '../utils/types'
import customAxios from '../http/axiosInstance'
import { dtoToProfile } from '../utils/mappings'
import { getLoggedUserUuid } from './authReducer'
import { UserInternalDto } from '../utils/dtos'

const initialState: ProfileState = {
  profile: null,
  loading: false,
  error: null,
}

export const fetchLoggedUserProfile = createAsyncThunk('profile/fetchLoggedUserUser', async () => {
  try {
    const uuid = getLoggedUserUuid()
    const response = await customAxios.get<UserInternalDto>(`/users/${uuid}`)
    return dtoToProfile(response.data)
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch user')
  }
})
const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoggedUserProfile.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchLoggedUserProfile.fulfilled, (state, action: PayloadAction<Profile>) => {
        state.loading = false
        state.profile = action.payload
      })
      .addCase(fetchLoggedUserProfile.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to fetch logged user'
      })
  },
})

export const selectProfile = (state: { profile: { profile: Profile } }) => state.profile.profile
export const selectProfileLoading = (state: { profile: { loading: boolean } }) =>
  state.profile.loading
export const selectProfileError = (state: { profile: { error: string | null } }) =>
  state.profile.error

export default profileSlice.reducer
