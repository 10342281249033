import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { requestTokenRefresh } from '../../../business/reducers/authReducer'
import { ROUTE_LOGIN, ROUTE_PROFILE } from '../../../business/utils/routes'
import { AppDispatch, RootState } from '../../../business/store/store'
import { STATUS_ERROR, STATUS_SUCCESS } from '../../../business/utils/constants'

const Landing: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { refreshStatus } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    dispatch(requestTokenRefresh())
  }, [dispatch])

  useEffect(() => {
    if (refreshStatus === STATUS_SUCCESS) {
      navigate(ROUTE_PROFILE)
    } else if (refreshStatus === STATUS_ERROR) {
      navigate(ROUTE_LOGIN)
    }
  }, [refreshStatus, navigate])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: '20px',
      }}
    >
      <p>Loading...</p>
    </div>
  )
}

export default Landing
