import React from 'react'
import PrimaryButton from '../../components/Button'
import GoogleIcon from '@mui/icons-material/Google'
import { Card, CardContent, CardHeader } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Login: React.FC = () => {
  const { t } = useTranslation()
  const handleLogin = () => {
    window.location.href = process.env.REACT_APP_API_URL + '/auth/google'
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          width: '50vw',
          height: '50vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <CardHeader title={t('welcome')} />
        <CardContent>
          <PrimaryButton onClick={handleLogin}>
            <GoogleIcon sx={{ mr: 1 }} />
            {t('loginWithGoogle')}
          </PrimaryButton>
        </CardContent>
      </Card>
    </div>
  )
}

export default Login
