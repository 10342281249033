import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User, UserState } from '../utils/types'
import customAxios from '../http/axiosInstance'
import { UserDto } from '../utils/dtos'
import { dtoToUser } from '../utils/mappings'

const initialState: UserState = {
  users: [],
  loading: false,
  error: null,
}

export const fetchUsers = createAsyncThunk('user/fetchUsers', async () => {
  try {
    const response = await customAxios.get<UserDto[]>('/users/external')
    return response.data.map((userDto) => dtoToUser(userDto))
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch users')
  }
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.loading = false
        state.users = action.payload
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to fetch users'
      })
  },
})

export const selectUsers = (state: { user: { users: User[] } }) => state.user.users
export const selectUserLoading = (state: { user: { loading: boolean } }) => state.user.loading
export const selectUserError = (state: { user: { error: string | null } }) => state.user.error

export default userSlice.reducer
