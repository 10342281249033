import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: {
      welcome: 'Welcome to Mercury',
      loginWithGoogle: 'Login with Google',
      navbarItem1: 'New Meeting',
      navbarItem2: 'Meeting List',
      menuLogout: 'Logout',
      menuProfile: 'Profile',
      meetings: {
        table: {
          header1: 'Title',
          header2: 'Date',
          header3: 'Step',
          header4: 'Status',
          header5: 'Creation date',
          header6: 'Last update',
          header7: 'Actions',
        },
      },
      meetingForm: {
        dateLabel: 'Date',
        titleLabel: 'Title',
        userAutocompleteHint: 'Select user',
        userAutocompleteLoading: 'Loading users...',
        spaceAutocompleteHint: 'Select space',
        spaceAutocompleteLoading: 'Loading spaces...',
        sourceAutocompleteHint: 'Select source file',
        sourceAutocompleteLoading: 'Loading source folder...',
        externalUsersAccordionTitle: 'External users',
        internalUsersAccordionTitle: 'Internal users',
        userTextFieldLabel: 'Insert user',
        submitText: 'Create Meeting',
      },
      profile: {
        genActiveLabel: 'Generate active',
        sourceDirectoryLabel: 'Source directory',
        genActiveYes: 'Yes',
        genActiveNo: 'No',
        activateGenButton: 'Activate Generation',
        deactivateGenButton: 'Deactivate Generation',
        activateSyncDialog: {
          title: 'Synchronize folder',
          content: 'This will synchronize the selected folder. Are you sure?',
        },
        deactivateSyncDialog: {
          title: 'Remove synchronization',
          content: 'This will remove the synchronization of the selected folder. Are you sure?',
        },
      },
      dialog: {
        confirm: {
          confirmButton: 'Confirm',
          cancelButton: 'Cancel',
        },
        retry: {
          title: 'Restart',
          content: 'This will restart the meeting processing from the last step. Are you sure?',
        },
        addMeeting: {
          title: 'Start Meeting Generation ?',
          content:
            'By starting the procedure the meeting will be generated. This can take some time. You will be notified during the various steps. Are you sure?',
        },
      },
    },
  },
  it: {
    translation: {
      welcome: 'Benvenuto in Mercury',
      loginWithGoogle: 'Login con Google',
      navbarItem1: 'Nuovo Meeting',
      navbarItem2: 'Elenco Meeting',
      menuLogout: 'Logout',
      menuProfile: 'Profilo',
      meetings: {
        table: {
          header1: 'Titolo',
          header2: 'Data',
          header3: 'Step',
          header4: 'Stato',
          header5: 'Data creazione',
          header6: 'Data aggiornamento',
          header7: 'Azioni',
        },
      },
      meetingForm: {
        dateLabel: 'Data',
        titleLabel: 'Titolo',
        userAutocompleteHint: 'Seleziona utente',
        userAutocompleteLoading: 'Caricamento utenti...',
        spaceAutocompleteHint: 'Seleziona spazio',
        spaceAutocompleteLoading: 'Caricamento spazi...',
        sourceAutocompleteHint: 'Seleziona file sorgente',
        sourceAutocompleteLoading: 'Caricamento cartella di sorgente...',
        externalUsersAccordionTitle: 'Utenti esterni',
        internalUsersAccordionTitle: 'Utenti interni',
        userTextFieldLabel: 'Inserisci utente',
        submitText: 'Crea Meeting',
      },
      profile: {
        genActiveLabel: 'Generazione attiva',
        genActiveYes: 'Si',
        genActiveNo: 'No',
        sourceDirectoryLabel: 'Cartella sorgente',
        activateGenButton: 'Attiva Generazione',
        deactivateGenButton: 'Disattiva Generazione',
        activateSyncDialog: {
          title: 'Sincronizza cartella',
          content: 'Questa azione sincronizzerà la cartella selezionata. Sicuro?',
        },
        deactivateSyncDialog: {
          title: 'Rimuovi sincronizzazione',
          content:
            'Questa azione rimuoverà la sincronizzazione della cartella selezionata. Sicuro?',
        },
      },
      dialog: {
        confirm: {
          confirmButton: 'Conferma',
          cancelButton: 'Annulla',
        },
        retry: {
          title: 'Riavvio',
          content: 'Questo reinizializza il processo di meeting dallo step precedente. Sicuro?',
        },
        addMeeting: {
          title: 'Avviare Generazione Meeting ?',
          content:
            'Avviando la generazione del meeting si avvira il processo di meeting transcribe che potrebbe richiedere parecchio tempo. Riceverai una notifica durante i vari step di completamento. Vuoi procedere ?',
        },
      },
    },
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
