import axios from 'axios'
import { AppStore } from '../store/store'

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

let storeInstance: AppStore | null = null

export const setupInterceptors = (store: AppStore) => {
  storeInstance = store
  customAxios.interceptors.request.use(
    (config) => {
      const jwtToken = store.getState().auth.accessToken
      if (jwtToken) {
        config.headers.Authorization = `Bearer ${jwtToken}`
      }
      config.timeout = 10000
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export const updateInterceptor = () => {
  customAxios.interceptors.request.use(
    (config) => {
      const jwtToken = storeInstance?.getState().auth.accessToken
      if (jwtToken) {
        config.headers.Authorization = `Bearer ${jwtToken}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export default customAxios
