import { Button, DialogContentText } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'
import React from 'react'

const DialogConfirm = (props: {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  content: string
}) => {
  const { open, onClose, onConfirm, title, content } = props
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('dialog.confirm.cancelButton')}
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {t('dialog.confirm.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirm
