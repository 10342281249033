import { Snackbar } from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import React from 'react'

const ErrorAlert = (props: { errorMessage: string }) => {
  const [open, setOpen] = useState(true)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (_event: Event | SyntheticEvent<any>, reason: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={props.errorMessage}
    />
  )
}

export default ErrorAlert
