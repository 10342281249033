import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Source, SourceState } from '../utils/types'
import customAxios from '../http/axiosInstance'
import { SourceDto } from '../utils/dtos'
import { dtoToSource } from '../utils/mappings'

const initialState: SourceState = {
  sources: [],
  loading: false,
  error: null,
}

export const fetchSources = createAsyncThunk('source/fetchSources', async () => {
  try {
    const response = await customAxios.get<SourceDto[]>('/meetings/sources')
    return response.data.map((sourceDto) => dtoToSource(sourceDto))
  } catch (error) {
    console.error(error)
    throw new Error('Failed to fetch sources')
  }
})

const sourceSlice = createSlice({
  name: 'source',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSources.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchSources.fulfilled, (state, action: PayloadAction<Source[]>) => {
        state.loading = false
        state.sources = action.payload
      })
      .addCase(fetchSources.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to fetch sources'
      })
  },
})

export const selectSources = (state: { source: { sources: Source[] } }) => state.source.sources
export const selectSourceLoading = (state: { source: { loading: boolean } }) => state.source.loading
export const selectSourceError = (state: { source: { error: string | null } }) => state.source.error

export default sourceSlice.reducer
