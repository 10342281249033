import styled from '@emotion/styled'
import React from 'react'
import {
  TableCell,
  tableCellClasses,
  TableRow,
  TableContainer,
  Paper,
  TableHead,
  TableBody,
  Table,
  IconButton,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TableInfo } from '../../business/utils/types'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#ccc',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const DataTable = (props: { tableInfo: TableInfo }) => {
  const { t } = useTranslation()

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {props.tableInfo.headers.map((header) => (
              <StyledTableCell key={header}>{t(header)}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableInfo.data.map((item) => (
            <StyledTableRow key={item.uuid}>
              {item.columns.map((column, index) => (
                <StyledTableCell
                  component="th"
                  scope="row"
                  key={'column' + item.uuid + '_' + index}
                >
                  {column}
                </StyledTableCell>
              ))}
              {item.actions && (
                <StyledTableCell component="th" scope="row" key={'actions' + item.uuid}>
                  {item.actions.map((action, index) => (
                    <IconButton
                      key={item.uuid + '_' + index}
                      onClick={() => action.action(item.uuid)}
                    >
                      {action.icon}
                    </IconButton>
                  ))}
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DataTable
